import React, { useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import { firebaseConfig } from "../../firebase/FireBase";
import moment from "moment";
import { saveStudent } from "../../services/savestudents/SaveStudent";
import { getAllSaveStudents } from "../../services/savestudents/GetAllStudents";
import { savedStdArray } from "./ChatArray";

const ChatRooms = ({
  key,
  userId,
  getMessages,
  roomId,
  lastMessage,
  lastTime,
  seen,
  messageSenderId,
  isActive,
}) => {
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  let user_id = JSON.parse(
    localStorage.getItem("sparrowSignIn")
  )?.user?.id.toString();
  let user_Role = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.role;

  let finalLastTime = moment.unix(lastTime / 1000).calendar();
  // console.log(seen, user_id);
  //3. getting details of other users in usersList || users are stored in item
  const [item, setItem] = useState([]);
  // console.log(item[0]?.id);
  useEffect(() => {
    getUserById();
    getSavedStudentFunc();
  }, []);

  const getUserById = async () => {
    let finalUserId = "";
    userId.forEach((element) => {
      // filtering the logged in user
      if (element !== user_id) {
        finalUserId = element;
      }
    });
    const usersCol = query(
      collection(db, "Users"),
      where("id", "==", finalUserId.trim())
    );
    const usersSnapshot = await getDocs(usersCol);
    const usersList = usersSnapshot.docs.map((doc) => doc.data());
    // after setting details of user and send to getMessages Func
    setItem(usersList);
  };

  // console.log(item[0]);
  // console.log(user_id);
  // saving students process
  const [loder, setLoder] = useState(false);

  const saveStudents = async (userData) => {
    setLoder(true);
    // debugger
    const data = {
      client_id: parseInt(user_id),
      student_id: parseInt(userData?.id),
    };
    const resp = await saveStudent(data);
    if (resp.status === 200) {
      // getSavedStudentFunc is because we have to imediately re render users
      // await and setloader false can be removed
      await getSavedStudentFunc();
      // Set loader to false after the loading process is complete
      setLoder(false);
    }
  };

  //  after getting saved students we return an array with names of students and by that array  we are hiding the image below to show the students that are added to favourites
  const [customSavedStd, setcustomSavedStd] = useState([]);
  const getSavedStudentFunc = async () => {
    // debugger
    const response1 = await getAllSaveStudents(parseInt(user_id));
    if (response1.status === 200) {
      setcustomSavedStd(savedStdArray(response1?.data?.student));
    }
  };
  // console.log(customSavedStd);
  // console.log("sender_id " + messageSenderId);
  // item[0]?.id are the other id's except loggedIn id
  // user_id is sender/loggedIn id

  return (
    // <div
    //   className={`chatBody ${messageSenderId !== user_id && seen === false ? "unreadChatColor" : ""} `}
    //   onClick={(e) => getMessages(item[0], roomId, e)}
    // >
    <div
      className={`chatBody ${isActive ? "chatBodyActive" : ""} ${
        messageSenderId !== user_id && seen === false ? "unreadChatColor" : ""
      }`}
      onClick={(e) => getMessages(item[0], roomId, e)}
    >
      <div className="chatUser">
        <div>
          <img className="chatImg" src={item[0]?.imageUrl} alt="" />
        </div>
        <div className="chatUserContent">
          <div className="setContentChatUser">
            <h5 className="chatUserContentH5">
              {item[0]?.name?.charAt(0).toUpperCase() + item[0]?.name?.slice(1)}
            </h5>
            <div className="chatUserTimeMain dispFlrxChht">
              <span className="chatUserTime">{finalLastTime}</span> &nbsp;
              &nbsp;
              {user_Role === "client" ? (
                customSavedStd?.includes(item[0]?.name) ? (
                  <img
                    src="/assets/images/cutomerchatscreen/goldenChat.svg"
                    alt=""
                  />
                ) : loder ? (
                  <div className="lds-spinner minorLod">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                ) : (
                  <div className="hoverTextDiv hver1" style={{ zIndex: "9" }}>
                    <img
                      className="labelImg"
                      src="/assets/images/cutomerchatscreen/chat bookmark.svg"
                      alt=""
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent click event from bubbling up to the parent div
                        saveStudents(item[0]);
                      }}
                    />
                    <span className="hoverText">Add to favorite students</span>
                  </div>
                )
              ) : (
                ""
              )}
            </div>
          </div>
          <p className="chatUserContentp">{lastMessage}</p>
        </div>
      </div>
    </div>
  );
};

export default ChatRooms;

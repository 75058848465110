import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router";
import Swal from "sweetalert2";
import { ShowAllStudents } from "../../services/users/ShowAllStudents";
import AdvacnceSearchBar from "../advancesearchbar/AdvacnceSearchBar";
import { useSingleOrderDraftInfo } from "../hooks/getSingleOrderDraft";
import StudentInviteCard from "./customerinvitecards/StudentInviteCard";
import Loader from "../loader/Loader";
import InfiniteScroll from "react-infinite-scroll-component";

const CustomerInviteStudents = () => {
  let inviteCount = 0;
  const student_role = 2;
  const { orderId } = useParams();
  const currentURL = window.location.href;
  const urlParts = currentURL.split("/");
  const task_id = urlParts[urlParts.length - 2];
  const singleOrder = useSingleOrderDraftInfo(parseInt(task_id));
  let user_id = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.id;

  const [searchedValue, setSearchedValue] = useState();
  const [loder, setLoder] = useState(true);
  const [allStudent, setAllStudent] = useState([]);
  const [displayedStudents, setDisplayedStudents] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [isSearching, setIsSearching] = useState(false);

  const getAllStudentFunc = async () => {
    const response1 = await ShowAllStudents(
      student_role,
      user_id,
      parseInt(orderId)
    );
    if (response1.status === 200) {
      const shuffledArray = response1?.data?.users.sort(
        (a, b) => 0.5 - Math.random()
      );
      setAllStudent(shuffledArray);
      setDisplayedStudents(shuffledArray.slice(0, 12));
      setLoder(false);
    } else {
      Swal.fire({
        title: response1.data.message,
        timer: 1500,
        icon: "error",
        showConfirmButton: false,
      });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      Swal.fire({
        title:
          "Please invite a few suitable students for your task to find the help you need in time",
        showConfirmButton: true,
        customClass: "stModal stModalinvite",
      });
    }, 1000);
    getAllStudentFunc();
  }, []);

  // const handleViewStudent = (studentArray) => {
  //   setAllStudent(studentArray);
  //   setDisplayedStudents(studentArray?.slice(0, 12));
  // };
  const handleViewStudent = (studentArray, searchValue) => {
    // Reset the displayed students to only the first 12 of the new search result
    setDisplayedStudents(studentArray.slice(0, 12));

    // Ensure all students are updated in state
    setAllStudent(studentArray);

    // Reset hasMore to true if the new search result has more than 12 students
    setHasMore(studentArray.length > 12);

    // Scroll to the student section
    const element = document.getElementById("allStudentSection");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const fetch12MoreStudents = () => {
    let nextStudents;
    if (isSearching) {
      nextStudents = allStudent.slice(
        displayedStudents.length,
        displayedStudents.length + 12
      );
    } else {
      nextStudents = allStudent.slice(
        displayedStudents.length,
        displayedStudents.length + 12
      );
    }

    setDisplayedStudents([...displayedStudents, ...nextStudents]);
    if (displayedStudents.length + nextStudents.length >= allStudent.length) {
      setHasMore(false);
    }
  };
  return (
    <>
      {loder ? (
        <div className="height100vh height10vh">
          <Loader />
        </div>
      ) : (
        <div className="viewAllStudents">
          <Container className="text-center pl90">
            <h2 className="mt140">
              Search <span className="green">Students</span>
              <div style={{ position: "relative" }}>
                <span>
                  <img
                    className="info3 info3C"
                    src="/assets/images/newlogos/Info.svg"
                    alt=""
                  />
                  <span className="hoverTextForR3 hoverTextForR3C">
                    Search for students you're looking to hire by entering your
                    location and task. The advanced search filter enables you to
                    narrow your search for more precise results. Click on the
                    profile card of each student to see more information.
                  </span>
                </span>
              </div>
            </h2>
            <AdvacnceSearchBar
              searchedValue={setSearchedValue}
              updateStudentResult={(studentArray, searchValue) =>
                handleViewStudent(studentArray, searchValue)
              }
              orderId={orderId}
              fromInvite={true}
            />

            <InfiniteScroll
              dataLength={displayedStudents?.length}
              next={fetch12MoreStudents}
              hasMore={hasMore}
              // loader={<h4>Loading...</h4>}
              // endMessage={
              //   <p style={{ textAlign: "center" }}>
              //     <b>Yay! You have seen it all</b>
              //   </p>
              // }
              style={{ overflow: "none" }}
            >
              <Row className="mL50p viewAllStCards" id="studentInviteCard">
                {displayedStudents?.map((singleStd, index) => {
                  if (singleStd?.email_verified_at !== null) {
                    return (
                      <>
                        <Col md={4} className="mb-5">
                          <StudentInviteCard
                            inviteCount={inviteCount}
                            studentData={singleStd}
                            key={index}
                            setAllStudent={setAllStudent}
                            singleOrder={singleOrder}
                            searchedValue={searchedValue}
                          />
                        </Col>
                      </>
                    );
                  }
                  return null;
                })}
              </Row>
            </InfiniteScroll>
          </Container>
        </div>
      )}
    </>
  );
};

export default CustomerInviteStudents;

import { useState, useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  timeSlots,
  days,
} from "../../services/availibility&timeslots/Availibility";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { getLocations100Random } from "../../services/locations/Locations";
import { SearchStudent } from "../../services/users/SearchStudent";
import Swal from "sweetalert2";
import AuthContext from "../../context/AuthContext";

// import Select from "react-select";
import { isMobile } from "react-device-detect";
import { colourStyles, colourStylesLocation } from "../../utils/constants";
import { ShowAllStudents } from "../../services/users/ShowAllStudents";

const AdvacnceSearchBar = (props) => {
  const [loder, setLoder] = useState(true);

  const auth = useContext(AuthContext);
  let user_id = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.id;
  const [adv_toBeShow, setAdv_toBeShow] = useState(isMobile ? false : true);

  const handleToBeShow = () => {
    if (adv_toBeShow) {
      setAdv_toBeShow(false);
    } else setAdv_toBeShow(true);
  };
  const imageForDropDownAdv = adv_toBeShow
    ? "/assets/images/viewAll/ArrowUp.svg"
    : "/assets/images/viewAll/ArrowDown.svg";

  // get timing slots
  const [timeSlot, setTimeSlot] = useState();
  const getTimeFunc = async () => {
    const response1 = await timeSlots();

    if (response1.status === 200) {
      setTimeSlot(response1.data);
      setLoder(false);
    } else {
      Swal.fire({
        title: response1.data.message,
        timer: 1500,
        icon: "error",
        showConfirmButton: false,
      });
    }
  };

  // get working days
  const [workdays, setworkdays] = useState();
  const getDaysFunc = async () => {
    const response = await days();

    if (response.status === 200) {
      setworkdays(response.data);
    } else {
      Swal.fire({
        title: response.data.message,
        timer: 1500,
        icon: "error",
        showConfirmButton: false,
      });
    }
  };

  // get location to show first
  const [randomLocation, setRandomLocation] = useState([]);
  const GetlocationsRamdomFunc = async () => {
    const optionlocation = [];
    const resp = await getLocations100Random();
    resp?.data?.map((singleLocation) => {
      return optionlocation.push({
        label:
          singleLocation.city +
          " " +
          singleLocation.state +
          "," +
          singleLocation.zip,
        value: singleLocation.id,
      });
    });
    setRandomLocation(optionlocation);
  };

  useEffect(() => {
    getDaysFunc();
    getTimeFunc();
    GetlocationsRamdomFunc();
  }, []);

  const CallFunctionforData = async (originalObject) => {
    const convertedObject = {
      skills: originalObject.skills,
      rating: originalObject.rating,
      day: originalObject.day,
      time: originalObject.time,
      vehicle: originalObject.vehicle,
      minimum_hourly_rate: originalObject.minimum_hourly_rate,
      maximum_hourly_rate: originalObject.maximum_hourly_rate,
      zip: originalObject.location,
    };
    let resp = {};
    if (props.orderId != null || props.orderId != undefined) {
      resp = await ShowAllStudents(
        2,
        user_id,
        parseInt(props?.orderId),
        convertedObject
      );
    } else {
      resp = await SearchStudent(convertedObject);
    }

    props.updateStudentResult(resp?.data?.users, true);
    props.searchedValue(selectedValue1.value);
  };

  useEffect(() => {
    const storedDataJSON = sessionStorage.getItem("pageSearch");
    if (storedDataJSON) {
      const storedData = JSON.parse(storedDataJSON);
      if (storedData.location) {
        const selectedLocation = auth.locations?.filter((i) =>
          i.label.toLowerCase().includes(storedData.location)
        );
        setValue("location", selectedLocation[0]);
      }
      if (storedData.skills && optionTaskValue) {
        const selectedSkill = optionTaskValue?.filter((i) =>
          i.label.toLowerCase().includes(storedData.skills.toLowerCase())
        );
        if (selectedSkill) {
          setSelectedValue1(selectedSkill[0]);
        }
      }

      if (storedData.day) {
        const selectedDay = optionDay?.filter(
          (i) => i.value === storedData.day
        );
        if (selectedDay) {
          setValue("daySearch", selectedDay[0]);
        }
      }
      if (storedData.time) {
        const selectedTime = optionTime?.filter(
          (i) => i.value === storedData.time
        );
        if (selectedTime) {
          setValue("timeSearch", selectedTime[0]);
        }
      }
      if (storedData.rating) {
        setValue("rating", storedData.rating);
        setSelectedOption(storedData.rating.toString());
      }
      if (storedData.vehicle) {
        setValue(
          "vehicle",
          storedData.vehicle === 1 ? "OwnVehicle" : "OtherVehicle"
        ); // Assuming vehicle `1` is "Own Vehicle"
        setSelectedOption2(
          storedData.vehicle === 1 ? "OwnVehicle" : "OtherVehicle"
        );
      }
      if (storedData.minimum_hourly_rate && storedData.maximum_hourly_rate) {
        const wageRange = `${storedData.minimum_hourly_rate}-${storedData.maximum_hourly_rate}`;
        setValue("minWage", wageRange);
        setSelectedOption1(wageRange);
      }
      if (props.orderId != null || props.orderId != undefined) {
        storedData["order_id"] = props.orderId;
      }

      // debugger;
      CallFunctionforData(storedData);
      // const resp = await SearchStudent(dataObj);
      //
      // props.updateStudentResult(resp?.data?.users, true);
      // props.searchedValue(selectedValue1.value);
    }
  }, [timeSlot]);

  const optionDay = [];
  if (workdays?.length > 0) {
    let order = {
      monday: 1,
      tuesday: 2,
      wednesday: 3,
      thursday: 4,
      friday: 5,
      saturday: 6,
      sunday: 7,
    };

    workdays.sort(function (a, b) {
      return order[a.day] - order[b.day];
    });

    workdays?.map((singleDay) => {
      return optionDay.push({
        label: singleDay?.day.charAt(0).toUpperCase() + singleDay?.day.slice(1),
        value: singleDay.id,
      });
    });
  }

  const optionTime = [];
  if (timeSlot?.length > 0) {
    timeSlot?.map((singleTime) => {
      return optionTime.push({
        label: singleTime?.slot,
        value: singleTime.id,
      });
    });
  }

  const filterLocationOption = (inputValue) => {
    if (inputValue.length > 1) {
      return auth.locations?.filter((i) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      );
    } else {
      let result = auth.locations?.filter((i) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      return result.splice(0, 80);
    }
  };

  const loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(filterLocationOption(inputValue));
    }, 1000);
  };

  // below code exist for checking and unchecking checkbox also making mandatory to select only one checkbox at a time
  // for rating
  const [selectedOption, setSelectedOption] = useState(null);
  console.log(selectedOption);
  const handleOptionChange = (e) => {
    setSelectedOption(
      e.target.value === selectedOption ? null : e.target.value
    );
  };
  // for hourly wage
  const [selectedOption1, setSelectedOption1] = useState(null);

  const handleOptionChange1 = (e) => {
    setSelectedOption1(
      e.target.value === selectedOption1 ? null : e.target.value
    );
  };
  // for transportation
  const [selectedOption2, setSelectedOption2] = useState(null);

  const handleOptionChange2 = (e) => {
    setSelectedOption2(
      e.target.value === selectedOption2 ? null : e.target.value
    );
  };

  // task field
  // option for task to make it option
  const optionTaskValue = [
    { value: "Child Care", label: "Child Care" },
    { value: "Tutoring", label: "Tutoring" },
    { value: "Coaching", label: "Sports Coaching" },
    { value: "Yard Work", label: "Yard Work" },
    { value: "Moving", label: "Moving" },
    { value: "Grocery Delivery", label: "Grocery Delivery" },
    { value: "Housekeeping", label: "Housekeeping" },
    { value: "Pet Sitting", label: "Pet Sitting" },
    { value: "Elder Care", label: "Elder Care" },
    { value: "Window Cleaning", label: "Window Cleaning" },
    { value: "Other", label: "Other" },
  ];
  const [selectedValue1, setSelectedValue1] = useState("");

  const handleMenuOpen = () => {
    setSelectedValue1(null); // Set the selected value to null when the dropdown is opened
  };
  // console.log(selectedValue1);
  // API hitting
  const {
    control,
    register,
    handleSubmit,
    setValue,
    resetField,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      location: null, // Ensure initial default value is set to null or appropriate default
    },
  });

  const clearPageSearch = () => {
    sessionStorage.removeItem("pageSearch"); // Remove 'pageSearch' from sessionStorage

    reset({
      location: null,
      daySearch: null,
      timeSearch: null, // Clearing the 'location' field
    });
    reset();
    setSelectedValue1(null);
    setSelectedOption1(null);
    setSelectedOption(null);
    setSelectedOption2(null);
  };

  const onSubmit = async (fData) => {
    if (selectedValue1 === "Task") {
      return;
    }
    let dataObj = {
      // skills: fData.taskInputValue.value,
      skills: selectedValue1?.value,
    };
    // rating
    if (selectedOption) {
      dataObj["rating"] = parseFloat(selectedOption);
    }

    if (fData.daySearch) {
      dataObj["day"] = parseInt(fData.daySearch?.value);
    }
    if (fData.timeSearch) {
      dataObj["time"] = parseInt(fData.timeSearch?.value);
    }

    // transportation
    if (selectedOption2 && selectedOption2 == "driverLicense") {
      dataObj["licence"] = 1;
    } else if (selectedOption2 && selectedOption2 == "OwnVehicle") {
      dataObj["vehicle"] = 1;
    }
    // hourly wage
    if (selectedOption1) {
      if (selectedOption1.includes("-")) {
        let wageArray = selectedOption1.split("-");
        let min = wageArray[0];
        let max = wageArray[1];
        dataObj["minimum_hourly_rate"] = parseInt(min);
        dataObj["maximum_hourly_rate"] = parseInt(max);
      } else {
        dataObj["minimum_hourly_rate"] = parseInt(selectedOption1);
      }
    }

    //for location
    if (fData.location && fData.location.label) {
      let arrayForCityZip = fData.location.label.split(",");
      dataObj["zip"] = parseInt(arrayForCityZip[1]);
    }

    if (props.orderId != null || props.orderId != undefined) {
      dataObj["order_id"] = props.orderId;
    }
    console.log("dataObj : ", dataObj);
    // debugger;
    // const resp = await SearchStudent(dataObj);
    let resp = {};
    if (props.orderId != null || props.orderId != undefined) {
      resp = await ShowAllStudents(
        2,
        user_id,
        parseInt(props?.orderId),
        dataObj
      );
    } else {
      resp = await SearchStudent(dataObj);
    }
    if (resp.status === 200) {
      if (resp?.data?.users.length == 0) {
        Swal.fire({
          title: "No user Found.",
          timer: 1500,
          icon: "warning",
          showConfirmButton: false,
        });
        props.updateStudentResult([]);
      } else {
        Swal.fire({
          title: "Students Found.",
          timer: 1500,
          icon: "success",
          showConfirmButton: false,
        });
        var dataToStore = {
          pageName: props?.fromInvite ? "InviteStudent" : "AdvanceSearch",
        };

        // Adding properties from dataObj to dataToStore
        if (dataObj.skills !== undefined) {
          dataToStore.skills = dataObj.skills;
        }
        if (dataObj.rating !== undefined) {
          dataToStore.rating = dataObj.rating;
        }
        if (dataObj.day !== undefined) {
          dataToStore.day = dataObj.day;
        }
        if (dataObj.time !== undefined) {
          dataToStore.time = dataObj.time;
        }
        if (dataObj.vehicle !== undefined) {
          dataToStore.vehicle = dataObj.vehicle;
        }
        if (dataObj.minimum_hourly_rate !== undefined) {
          dataToStore.minimum_hourly_rate = dataObj.minimum_hourly_rate;
        }
        if (dataObj.maximum_hourly_rate !== undefined) {
          dataToStore.maximum_hourly_rate = dataObj.maximum_hourly_rate;
        }
        if (dataObj.zip !== undefined) {
          dataToStore.location = dataObj.zip; // Mapping zip to location
        }

        // Storing the object in sessionStorage
        sessionStorage.setItem("pageSearch", JSON.stringify(dataToStore));
        setTimeout(() => {
          props.updateStudentResult(resp?.data?.users, true);
          props.searchedValue(selectedValue1?.value);
          // props.searchedValue(fData.taskInputValue.value);
          // navigate('/signUpRoute/step-two');
        }, 100);
      }
    } else {
      Swal.fire({
        title: resp.data.message,
        timer: 1500,
        icon: "error",
        showConfirmButton: false,
      });
    }
  };

  return (
    <div className="advanceSearch mt-5">
      <form
        id="advanceSearchForm"
        onSubmit={handleSubmit(onSubmit)}
        className="stepForm"
      >
        <div className="bannerFields viewStudentsField pb-4 reviewSection_dashbord row">
          <div
            style={{ textAlign: "left" }}
            className="col-lg-5 col-md-6 col-sm-6 col-12 px-0"
          >
            <Controller
              name="select"
              className="border-0"
              control={control}
              {...register("location")}
              render={({ field }) => (
                <AsyncSelect
                  {...field}
                  styles={colourStylesLocation}
                  className="cityField border-0 border-mb-fields"
                  cacheOptions
                  loadOptions={loadOptions}
                  defaultOptions={randomLocation}
                  // defaultOptions={[initialLocation]} // Set the default options as an array containing the initial location
                  placeholder="City or zip code"
                  // menuIsOpen={true}
                  menuPortalTarget={document.body}
                />
              )}
            />
          </div>
          <div className="customSearchField mb-customSearchField  col-lg-7 col-md-6 col-sm-6 col-12">
            <Controller
              name="select_task"
              {...register("taskInputValue")}
              render={({ field }) => (
                <Select
                  {...field}
                  value={selectedValue1}
                  onMenuOpen={handleMenuOpen}
                  onChange={(newValue) => {
                    setSelectedValue1(newValue);
                  }}
                  styles={colourStylesLocation}
                  menuPortalTarget={document.body}
                  options={optionTaskValue}
                  className="findTasksFields border-0 border-mb-fields"
                  placeholder="Task"
                />
              )}
              control={control}
            />

            {errors.taskInputValue && (
              <span className="eror">This field is required</span>
            )}
            <button type="submit" className="taskbutton primary center">
              Find Student
            </button>
          </div>
        </div>
        <div className="advanceSearchHed">
          <div className="pointercurser" onClick={handleToBeShow}>
            <h6 className="cjorta">
              {" "}
              <span>
                <img src="/assets/images/viewAll/advSearchIcon.svg" alt="" />
                &nbsp;&nbsp;&nbsp;
              </span>
              Advanced Search
            </h6>
          </div>
          <div
            className="d-inline-flex  justify-content-start"
            style={{ gap: 5 }}
          >
            {/* button for cclear  */}
            <span
              role="button"
              className="primary dashboardHederBtn clearFilter_btn  ml-0"
              onClick={() => clearPageSearch()} // Add onClick event handler
            >
              Clear Filter
            </span>
            <img
              className="cjotakro"
              src={imageForDropDownAdv}
              alt=""
              onClick={handleToBeShow}
            />
          </div>
        </div>
        {adv_toBeShow && (
          <div className="advanceSearchBody row">
            <div className="advanceBodyParts col-lg-3 col-md-4 col-sm-6 col-12">
              <div className="innerSetting">
                <h5 className="mb-2">Time</h5>
                <div className="selectDivAdv">
                  <Controller
                    name="daySearch"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="Select day"
                        className="advSelectViewAll mb-2"
                        options={optionDay}
                        styles={colourStyles}
                      />
                    )}
                  />

                  <Controller
                    name="timeSearch"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="Select time"
                        className="advSelectViewAll mb-2"
                        options={optionTime}
                        styles={colourStyles}
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            {/* rating */}
            <div className="advanceBodyParts advBodyPart_margin col-lg-3 col-md-4 col-sm-6 col-12">
              <div className="innerSetting">
                <h5 className="mb-2">Rating</h5>
                <div className="ratingStars">
                  <div className="pretty p-default p-round advanceBtn">
                    <input
                      name="rating"
                      id="starRating"
                      value="3"
                      type="checkbox"
                      checked={selectedOption === "3"}
                      onChange={handleOptionChange}
                    />
                    <div className="state p-success-o">
                      <label></label>
                    </div>
                  </div>
                  <div className="ms-1">
                    <img src="/assets/images/home/Star 8.svg" alt="" />
                    <img src="/assets/images/home/Star 8.svg" alt="" />
                    <img src="/assets/images/home/Star 8.svg" alt="" />
                    <img
                      src="/assets/images/customerDashboard/dullStarForAdvSearch.svg"
                      alt=""
                    />
                    <img
                      src="/assets/images/customerDashboard/dullStarForAdvSearch.svg"
                      alt=""
                    />
                  </div>
                </div>
                <div className="ratingStars">
                  <div className="pretty p-default p-round advanceBtn">
                    <input
                      name="rating"
                      id="starRating"
                      value="4"
                      type="checkbox"
                      checked={selectedOption === "4"}
                      onChange={handleOptionChange}
                    />
                    <div className="state p-success-o">
                      <label></label>
                    </div>
                  </div>
                  <div className="ms-1">
                    <img src="/assets/images/home/Star 8.svg" alt="" />
                    <img src="/assets/images/home/Star 8.svg" alt="" />
                    <img src="/assets/images/home/Star 8.svg" alt="" />
                    <img src="/assets/images/home/Star 8.svg" alt="" />
                    <img
                      src="/assets/images/customerDashboard/dullStarForAdvSearch.svg"
                      alt=""
                    />
                  </div>
                </div>
                <div className="ratingStars align-items-center">
                  <div className="pretty p-default p-round advanceBtn">
                    <input
                      name="rating"
                      id="starRating"
                      value="4.8"
                      type="checkbox"
                      // on client request (on day 07-04-2023) making 5 to 4.8
                      checked={selectedOption === "4.8"}
                      onChange={handleOptionChange}
                      // {...register("starRating")}
                      // onChange={(e) => { check(e.target, e.target.value) }}
                    />
                    <div className="state p-success-o">
                      <label></label>
                    </div>
                  </div>
                  <div className="ms-1">
                    <img src="/assets/images/home/Star 8.svg" alt="" />
                    <img src="/assets/images/home/Star 8.svg" alt="" />
                    <img src="/assets/images/home/Star 8.svg" alt="" />
                    <img src="/assets/images/home/Star 8.svg" alt="" />
                    <img src="/assets/images/home/Star 8.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>

            {/*  */}
            <div className="advanceBodyParts advBodyPart_margin col-lg-3 col-md-4 col-sm-6 col-12">
              <div className="innerSetting">
                <h5 className="mb-2">Hourly Wage</h5>
                <div className="advanceBodyDistance">
                  <div className="d-flex align-items-center flex-row mt-1">
                    <div className="pretty p-default p-round advanceBtn">
                      <input
                        name="minWage"
                        id="minWage"
                        value="20-30"
                        // type="radio"
                        // {...register("minWage")}
                        type="checkbox"
                        checked={selectedOption1 === "20-30"}
                        onChange={handleOptionChange1}
                      />
                      <div className="state p-success-o">
                        <label></label>
                      </div>
                    </div>
                    <div className="ms-1">
                      <div className="state p-success-o">
                        <label>$20 - $30</label>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex align-items-center flex-row mt-1">
                    <div className="pretty p-default p-round advanceBtn">
                      <input
                        name="minWage"
                        id="minWage"
                        value="30-40"
                        // type="radio"
                        // {...register("minWage")}
                        type="checkbox"
                        checked={selectedOption1 === "30-40"}
                        onChange={handleOptionChange1}
                      />
                      <div className="state p-success-o">
                        <label></label>
                      </div>
                    </div>
                    <div className="ms-1">
                      <div className="state p-success-o">
                        <label>$30 - $40</label>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex align-items-center flex-row mt-1">
                    <div className="pretty p-default p-round advanceBtn">
                      <input
                        name="minWage"
                        id="minWage"
                        value="40"
                        // type="radio"
                        // {...register("minWage")}
                        type="checkbox"
                        checked={selectedOption1 === "40"}
                        onChange={handleOptionChange1}
                      />
                      <div className="state p-success-o">
                        <label></label>
                      </div>
                    </div>
                    <div className="ms-1">
                      <div className="state p-success-o">
                        <label>$40 and above</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="advanceBodyParts advBodyPart_margin col-lg-3 col-md-4 col-sm-6 col-12">
              <div className="innerSetting">
                <h5 className="mb-2">Transportation</h5>
                <div className="advanceBodyDistance">
                  <div className="d-flex align-items-center flex-row mt-1">
                    <div className="pretty p-default p-round advanceBtn">
                      <input
                        name="transportation"
                        id="driver’s License"
                        value="driverLicense"
                        // type="radio"
                        // {...register("licenseOrVehicle")}
                        type="checkbox"
                        checked={selectedOption2 === "driverLicense"}
                        onChange={handleOptionChange2}
                      />
                      <div className="state p-success-o">
                        <label></label>
                      </div>
                    </div>
                    <div className="ms-1">
                      <div className="state p-success-o">
                        <label>Driver’s License</label>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex align-items-center flex-row mt-1">
                    <div className="pretty p-default p-round advanceBtn">
                      <input
                        name="transportation"
                        id="Own Vehicle"
                        value="OwnVehicle"
                        // type="radio"
                        // {...register("licenseOrVehicle")}
                        type="checkbox"
                        checked={selectedOption2 === "OwnVehicle"}
                        onChange={handleOptionChange2}
                      />
                      <div className="state p-success-o">
                        <label></label>
                      </div>
                    </div>
                    <div className="ms-1">
                      <div className="state p-success-o">
                        <label>Own Vehicle</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default AdvacnceSearchBar;

import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
// import FooterNav from '../mobilefooternav/FooterNav'
import { initializeApp } from "firebase/app";
import { useDebouncedCallback } from "use-debounce";
import {
  collection as fireStoreCollectione,
  query as fireStoreQuery,
  where as fireStoreWhere,
  getDocs as fireStoreDocs,
  onSnapshot,
  getFirestore,
  addDoc,
  orderBy,
  Timestamp,
  doc,
  updateDoc,
  // Firestore,
} from "firebase/firestore";
import { firebaseConfig } from "../../firebase/FireBase";
import ChatRooms from "./ChatRooms";
import ChatRespHed from "./ChatRespHed";
import moment from "moment";
import { days } from "../../services/availibility&timeslots/Availibility";
import _ from "lodash";

const StudentChatBar = () => {
  //

  // get working days
  const getDaysFunc = async () => {
    const response = await days();
    // if (response.status === 200) {
    //     setworkdays(response?.data);
    // }
  };
  useEffect(() => {
    getDaysFunc();
  }, []);

  // Two components for chat : ChatBar and ChatRooms
  const [loder, setLoder] = useState(true);
  const [loder2, setLoder2] = useState(false);
  // const [activeUser, setActiveUser] = useState(true);
  const [senderId, setSenderId] = useState();

  // chat starts from here
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  let user_id = JSON.parse(
    localStorage.getItem("sparrowSignIn")
  )?.user?.id.toString();
  let user_Image = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user
    ?.image;
  let user_Role = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.role;
  let user_Name = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user
    ?.first_name;

  const [chatRooms, setChatRooms] = useState([]);
  const [roomId, setRoomId] = useState("");
  const [receiverImg, setReciverImage] = useState("");
  const [receiverName, setReciverName] = useState("");
  const [users, setUsers] = useState([]);
  const [countUsers, setCountUsers] = useState(0);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  // const [chatRoomSelected, setChatRoomSelected] = useState("")
  // console.log(chatRooms);

  //1. setting list of users
  useEffect(() => {
    if (countUsers === 0) {
      getUsers(db);
      // getMessages(db);
      setCountUsers(1);
    }
  }, [countUsers]);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  const roomIdFromUrl = query.get("roomId");

  async function getUsers(db) {
    const roomsCol = fireStoreQuery(
      fireStoreCollectione(db, "ChatRooms"),
      fireStoreWhere("userIds", "array-contains", user_id)
    );
    const roomsSnapshot = await fireStoreDocs(roomsCol);
    const roomsList = roomsSnapshot.docs.map((doc) => doc.data());
    const sortedChatRooms = _.sortBy(
      roomsList,
      "timeStampOfLastMessage"
    ).reverse();

    setChatRooms(sortedChatRooms);
    setLoder(false);

    if (roomIdFromUrl) {
      const selectedRoom = sortedChatRooms.find(
        (room) => room.id === roomIdFromUrl
      );
      if (selectedRoom) {
        // Assuming you have a function to select a room and load messages
        selectRoomAndLoadMessages(selectedRoom);
      }
    }
  }

  const selectRoomAndLoadMessages = async (selectedRoom) => {
    // Adapted from your getMessages function
    // Set necessary states and load messages for the selectedRoom
    setRoomId(selectedRoom.id);
    // Load messages and other necessary data for the selectedRoom
  };

  // heavy SnapShot function
  // , { includeMetadataChanges: true }
  useEffect(() => {
    let unsubscribe = null; // Variable to store the unsubscribe function

    const fetchDocument = async () => {
      try {
        const q12 = fireStoreQuery(fireStoreCollectione(db, "ChatRooms"));
        unsubscribe = onSnapshot(q12, (snapshot) => {
          const chatRoomList = snapshot.docs.map((doc) => doc.data());
          const unreadMessage = chatRoomList?.filter((item) => {
            return item?.seen === false || item?.userIds?.includes(user_id);
          });
          const unreadMessageForUser = unreadMessage?.filter((item) => {
            return item?.userIds?.includes(user_id);
          });
          const sortedChatRooms = _.sortBy(
            unreadMessageForUser,
            "timeStampOfLastMessage"
          ).reverse();

          setChatRooms(sortedChatRooms);
        });
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };
    fetchDocument();
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [db, user_id]);

  useEffect(() => {
    const getInitialData = async () => {
      const q = fireStoreQuery(
        fireStoreCollectione(db, "ChatRooms"),
        fireStoreWhere("id", "==", roomIdFromUrl)
      );
      const currentUserId = JSON.parse(localStorage.getItem("sparrowSignIn"))
        ?.user?.id;

      const roomsSnapshot = await fireStoreDocs(q);
      // const roomsListId = roomsSnapshot.docs.map((doc) => ({
      //   id: doc.data().userIds[1],
      // }))[0];
      const roomsListId = roomsSnapshot.docs.map((doc) => {
        const userIds = doc.data().userIds;
        // Filter out the current user's ID and return the other ID
        const otherUserId = userIds.find(
          (id) => parseInt(id) !== parseInt(currentUserId)
        );
        return { id: otherUserId };
      })[0];
      // console.log("get ID:",  roomsListId);
      getMessages(roomsListId, roomIdFromUrl, null);
    };
    if (roomIdFromUrl) {
      getInitialData();
    }
  }, []);

  //4. showing All Messages in chat on Click of userList and then 5. || chatClass is used for responsive purpose
  const [chatClass, setChatClass] = useState("chatBar");
  const [chatStartedTextOnly, setChatStartedTextOnly] = useState(false);

  const getMessages = async (data, roomID, e) => {
    // debugger
    // this state(setChatStartedTextOnly) is only for showing chat has started text
    setChatStartedTextOnly(true);
    //
    getMessagesData(db);
    setRoomId(roomID);
    setSenderId(data?.id);
    // adding respChat for responsive purpose
    setChatClass("chatBar RespChat");
    // adding active class

    document.querySelectorAll(".chatBody").forEach((item) => {
      if (e.target.closest(".chatBody") === item) {
        item.classList.add("chatBodyActive");
      } else {
        item.classList.remove("chatBodyActive");
      }
    });

    // setting room here so we can send last msg to db || setting serverTime and message here because we have to show what was the last time and message when we chatted
    const q = fireStoreQuery(
      fireStoreCollectione(db, "ChatRooms"),
      fireStoreWhere("id", "==", roomID)
    );
    // console.log("clicked");
    const roomsSnapshot = await fireStoreDocs(q);
    const roomsListId = roomsSnapshot.docs.map((doc) => doc.id);
    const roomListIdString = roomsListId.toString();
    // console.log(roomListIdString);

    await updateDoc(doc(db, "ChatRooms", roomListIdString), {
      // lastmessage: message,
      // timeStampOfLastMessage: JSON.stringify(serverTime.seconds * 1000),
      // senderrId: user_id,
      seen: true,
    });

    // getting messages
    async function getMessagesData(db) {
      // get users here for setting image and recivername because otherwise these were getting because of double click on chatroom

      const q12 = fireStoreQuery(
        fireStoreCollectione(db, "Users"),
        fireStoreWhere("id", "==", data?.id)
      );
      const roomsSnapshot12 = await fireStoreDocs(q12);
      const roomsListId12 = roomsSnapshot12.docs.map((doc) => doc.data());
      setReciverImage(roomsListId12[0].imageUrl);
      setReciverName(roomsListId12[0].name);

      // Getting messages which have coming roomID from 3. In thenpm st Messages Collection on firebase
      const messagesCol = fireStoreQuery(
        fireStoreCollectione(db, "Messages"),
        fireStoreWhere("roomId", "==", roomID),
        orderBy("timeStamp", "asc")
      );

      const messagesSnapshot = await fireStoreDocs(messagesCol);
      const messagesList = messagesSnapshot.docs.map((doc) => doc?.data());

      let finalMessageList = messagesList.map((item) => {
        // create Date object
        return {
          senderId: item?.senderId,
          roomId: item?.roomId,
          timeStamp: moment
            .unix(item?.timeStamp / 1000)
            .format("MMM DD, YYYY, HH:mm"),
          // timeStamp: moment.unix(doc.data().timeStamp / 1000).format("LT"),
          message: item?.message,
          url: item?.url,
        };
      });
      // console.log(finalMessageList);
      // arrangment of messages
      let sortedDates = finalMessageList.sort(function (a, b) {
        return a.timeStamp - b.timeStamp; // Sort messages in ascending order based on timestamp
      });
      setMessages(sortedDates);
      // setUserTime(sortedDates[sortedDates.length - 1].timeStamp)
      setUsers(data);
    }
  };
  // console.log(messages);
  // 5. instantly showing message in chatBar as the user send or recieve messages

  useEffect(() => {
    if (roomId !== "") {
      const q = fireStoreQuery(
        fireStoreCollectione(db, "Messages"),
        fireStoreWhere("roomId", "==", roomId),
        orderBy("timeStamp", "asc")
      );
      onSnapshot(q, (querySnapshot) => {
        const messaages = [];
        querySnapshot.forEach((doc) => {
          messaages.push({
            message: doc.data().message,
            roomId: doc.data().roomId,
            senderId: doc.data().senderId,
            // timeStamp: moment.unix(doc.data().timeStamp / 1000).format("LT"),
            timeStamp: moment
              .unix(doc.data().timeStamp / 1000)
              .format("MMM DD, YYYY, HH:mm"),
            url: doc.data().url,
          });
        });
        let sortedDates = messaages.sort(function (a, b) {
          return a.timeStamp - b.timeStamp; // Sort messages in ascending order based on timestamp
        });
        setMessages(sortedDates);
        // console.log(sortedDates[sortedDates.length - 1].message)
        // calling getUsers function to instantly update last msg and time
        // getUsers(db)
      });
    }
    // console.log("changing")
  }, [db, roomId]);

  // send message to db || formating time
  // const [reciverId, setReciverId] = useState()
  const serverTime = Timestamp.now();

  async function saveMessage(e) {
    e.preventDefault();
    if (message !== "") {
      try {
        // console.log("rec " + reciverId);
        await addDoc(fireStoreCollectione(db, "Messages"), {
          message: message,
          roomId: roomId,
          senderId: user_id,
          timeStamp: JSON.stringify(serverTime.seconds * 1000),
          url: "",
          read: false,
        });
        // console.log(a.id)
        // setting room here so we can send last msg to db || setting serverTime and message here because we have to show what was the last time and message when we chatted

        const q = fireStoreQuery(
          fireStoreCollectione(db, "ChatRooms"),
          fireStoreWhere("id", "==", roomId)
        );
        const roomsSnapshot = await fireStoreDocs(q);
        const roomsListId = roomsSnapshot.docs.map((doc) => doc.id);
        const roomListIdString = roomsListId.toString();

        await updateDoc(doc(db, "ChatRooms", roomListIdString), {
          lastmessage: message,
          timeStampOfLastMessage: JSON.stringify(serverTime.seconds * 1000),
          senderId: user_id,
          seen: false,
        });
        setMessage("");
        // calling getUsers function to instantly update last msg and time
        // getUsers(db)
      } catch (error) {
        console.error("Error writing new message to Firebase Database", error);
      }
    } else {
      return false;
    }
  }

  // keeping the chat at the bottom
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Searching & Filtering

  function isTrue(arr, arr2) {
    return arr.every((i) => arr2.includes(i));
  }

  const debounced = useDebouncedCallback(async function (e) {
    // setting message list empty onChange
    setMessages([]);
    // starts filtering
    let finalUsers = [];

    if (e.target.value) {
      setLoder(true);

      const roomsCol = fireStoreQuery(
        fireStoreCollectione(db, "ChatRooms"),
        fireStoreWhere("userIds", "array-contains", user_id)
      );
      const roomsSnapshot = await fireStoreDocs(roomsCol);
      const roomsList = roomsSnapshot.docs.map((doc) => doc.data());
      roomsList.forEach((item) => {
        item.userIds.forEach((item) => {
          if (user_id !== item) finalUsers.push(item);
        });
      });

      const finalChatRooms = [];

      const FilteredChatRooms = finalUsers.map(async (user) => {
        const usersCol = fireStoreQuery(
          fireStoreCollectione(db, "Users"),
          fireStoreWhere("id", "==", user)
        );
        const usersSnapshot = await fireStoreDocs(usersCol);
        const usersList = usersSnapshot.docs.map((doc) => doc.data());

        if (
          usersList[0]?.name
            .toLowerCase()
            .indexOf(e.target.value.toLowerCase()) > -1
        ) {
          const rooms1Col = fireStoreQuery(
            fireStoreCollectione(db, "ChatRooms"),
            fireStoreWhere("userIds", "array-contains", usersList[0].id)
          );
          const rooms1Snapshot = await fireStoreDocs(rooms1Col);
          const rooms1List = rooms1Snapshot.docs.map((doc) => doc.data());
          rooms1List.forEach((item) => {
            if (isTrue(item.userIds, [usersList[0].id, user_id])) {
              finalChatRooms.push(item);
            }
          });
        }
      });

      await Promise.all(FilteredChatRooms);

      setLoder(false);
      setChatRooms(finalChatRooms);
    } else if (e.target.value === "") {
      setLoder(true);
      const roomsCol = fireStoreQuery(
        fireStoreCollectione(db, "ChatRooms"),
        fireStoreWhere("userIds", "array-contains", user_id)
      );
      const roomsSnapshot = await fireStoreDocs(roomsCol);
      const roomsList = roomsSnapshot.docs.map((doc) => doc.data());
      setChatRooms(roomsList);
      setLoder(false);
    }
  }, 1500);

  // responsive functionality removing for showing and removing classes and getting respitem from chatroom here to show single user data for name and image in responsive

  const handleClick = () => {
    setChatClass("chatBar");
  };

  // console.log(chatRooms);
  return (
    <div>
      {/*- In chatClass we are adding another class for which we are making responsive
            - setResonive1 class will displayed block on mobile and none on desktop */}
      <div className={chatClass}>
        <Row>
          <Col md={4} className="borderRight">
            <div className="chatMain">
              {/* this one is for desktop */}
              <div className="chatHed setResonive adjustChatHed">
                <input
                  className="filterInput"
                  type="text"
                  placeholder={
                    user_Role === "client" ? "Search Student" : "Search Client"
                  }
                  onChange={(e) => debounced(e)}
                />
                <div>
                  <img
                    className="chatSearch"
                    src="/assets/images/cutomerchatscreen/Searchvector.svg"
                    alt=""
                  />
                </div>
              </div>
              {/* this one is for responsive */}
              <div className="ChatResonive1NamesMain setResonive1">
                <h2>Chat</h2>
                <div className="customSearchField flex2 chatSrch">
                  <input
                    type="text"
                    className="findTasksField"
                    placeholder={
                      user_Role === "client"
                        ? "Search Student"
                        : "Search Client"
                    }
                    onChange={(e) => debounced(e)}
                  />
                  <button className="taskbutton primary center">SMS</button>
                </div>
              </div>
              {/* 2. Chatroom work */}
              {loder ? (
                <div className="height100vh">
                  <div className="lds-spinner chotaLoder">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              ) : (
                <div className="adjustingHeight">
                  {chatRooms?.map((item) => {
                    return (
                      <ChatRooms
                        key={item?.id}
                        userId={item?.userIds}
                        getMessages={getMessages}
                        roomId={item?.id}
                        userTime={item?.timeStampOfLastMessage}
                        lastMessage={item?.lastmessage}
                        lastTime={item?.timeStampOfLastMessage}
                        seen={item?.seen}
                        // chatRoomSelected={chatRoomSelected}
                        messageSenderId={item?.senderId}
                        isActive={roomId === item.id}
                        // recieverId={item?.recieverId}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </Col>
          <Col md={8} className="setResonive">
            {/* setResonive1 means block in mobile || exporting chatRooms again only for responsive as user will click on the person to chat so on top image,name will be shown of that person || .chatBar.RespChat .setResonive.col-md-8  and .chatBar.RespChat .borderRight.col-md-4 for previous css we are adding RespChat class*/}
            <div className="singleChathed setResonive1 pb0">
              <div>
                <Link to="" onClick={handleClick}>
                  {/* <img src="/assets/images/backArrw.png" alt="" /> */}
                  <img
                    src="/assets/images/arrw.png"
                    style={{ width: "14px", height: "12px" }}
                    alt=""
                  />
                </Link>
              </div>
              <ChatRespHed userData={senderId} />
            </div>
            {/*setResonive means block on desktop and none on responsive  */}
            <div className="chatHed setResonive">
              <h5>Chat</h5>
            </div>
            <div className="adjustingHeight2">
              {chatStartedTextOnly && (
                <p className="msgStartP">The chat has started</p>
              )}
              {loder2 ? (
                <div className="height100vh">
                  <div className="lds-spinner chotaLoder">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              ) : (
                messages?.map((msg) => {
                  // {console.log(messages);}
                  return (
                    <div>
                      {msg?.senderId === user_id ? (
                        <div className="floatRightContent">
                          <div className="rContentDiv">
                            <div>
                              <p className="rContentHed">{user_Name}</p>
                              <p className="rContentP">{msg?.timeStamp}</p>
                            </div>
                            <div>
                              <img
                                className="InnerImageChat"
                                src={user_Image}
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="typedMsgs">
                            <p className="typedMsgsP text-break">
                              {msg?.message}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div className="floatleftContent">
                          {/* 2nd User Messages */}
                          <div className="lContentDiv">
                            <div>
                              <img
                                className="InnerImageChat"
                                src={receiverImg}
                                // onError={handleImageError}
                                alt=""
                              />
                            </div>
                            <div>
                              <p className="rContentHed">{receiverName}</p>
                              <p className="rContentP">{msg?.timeStamp}</p>
                            </div>
                          </div>
                          <div className="rTypedMsgs">
                            {msg?.url ? (
                              <Link
                                className="typedMsgsP text-break notifiP"
                                to={msg.url}
                              >
                                {msg?.message}
                              </Link>
                            ) : (
                              <p className="typedMsgsP text-break">
                                {msg?.message}
                              </p>
                            )}
                          </div>
                        </div>
                      )}
                      <div ref={messagesEndRef} />
                    </div>
                  );
                })
              )}
            </div>

            <form onSubmit={(e) => saveMessage(e)} className="writeMessages">
              <div className="writeMessages1stdiv">
                <input
                  value={message}
                  className="inputMsgField"
                  placeholder="Message"
                  type="text"
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
              <div className="elementCenter">
                <button className="primary sendMsgBtn" type="submit">
                  Send
                </button>
              </div>
            </form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default StudentChatBar;
